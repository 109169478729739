<template>
  <div class="document" v-if="data">
    <router-link :to="route" class="document__icon">
      <IconComponent category="vuesax-bold" name="document-text" />
    </router-link>
    <router-link :to="route" class="document__content">
      <div class="document__header">
        <span class="document__number" v-if="data.number">№ {{ data.number }}</span>
        <time class="document__date" :datetime="data.accepted_at | robotDate" v-if="data.accepted_at">
          от {{ data.accepted_at | humanDateShort }}
        </time>
        <time
          class="document__date"
          :datetime="data.published_at | robotDate"
          v-if="data.published_at && activity"
        >
          {{ data.published_at | humanDateShort }}
        </time>
        <span class="document__category" v-if="data.document_type">{{ data.document_type.title }}</span>
      </div>
      <span class="document__title" v-if="data.title">{{ data.title }}</span>
      <span class="document__description" v-if="data.description">{{ data.description }}</span>
    </router-link>
    <a v-if="data.file" :href="data.file.path" :download="data.file.origin_name" class="btn btn--main">
      <IconComponent category="vuesax-bold" name="receive-square" />
      <span>
        Скачать
        <template v-if="data.file.type && data.file.size">
          ({{ data.file.type }}, {{ data.file.size }})
        </template>
      </span>
    </a>
  </div>
</template>
<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ListDocumentItem",
  props: {
    data: Object,
    activity: Boolean,
    link: Boolean,
  },
  computed: {
    route() {
      if (!this.link) {
        return {
          name: "document",
          params: { id: this.data.id },
        };
      } else {
        return {
          name: "",
        };
      }
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.document {
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 10px;
  padding 20px
  display grid
  grid-template-columns 60px 1fr auto
  grid-gap 20px
  width 100%
  align-items center
  +below(768px) {
    grid-template-columns 1fr
    grid-gap 15px
  }

  &:hover {
    border-color var(--main)
  }

  &__icon {
    background: var(--gray);
    border-radius: 100px;
    width 60px
    height: 60px;
    display flex
    align-items center
    justify-content center

    .icon {
      width 30px
      height: 30px;

      svg path {
        fill var(--main)
      }
    }
  }

  &__content {
    display grid
    grid-gap 10px
  }

  &__header {
    display flex
    align-items center
    gap 24px
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark-light);

    & > *:not(:last-child) {
      position relative

      &:after {
        content "•"
        font-weight 700
        absolute right -14px top bottom
        margin auto
        font-size: 1em;
        line-height: 14px;
        color: var(--dark-light);
      }
    }
  }

  &__number,
  &__category {
    font-weight: 700;
  }

  &__date {
    font-weight: 500;
  }

  &__title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    +below(768px) {
      font-size: 1em;
      line-height: 26px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 1em;
    line-height: 28px;
    color: var(--dark);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    +below(768px) {
      font-size: 0.875em;
      line-height: 26px;
    }
  }
}
</style>
