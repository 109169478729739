<template>
  <div class="critical-error">
    <div class="critical-error__title">404</div>
    <div class="critical-error__subtitle">Not Found</div>
    <div class="critical-error__text">Такой страницы не существует</div>
  </div>
</template>

<script>
import DEPARTMENT from "gql/queries/department.graphql";

export default {
  name: "NotFound",
  async asyncData({ res, apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEPARTMENT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
    if (res) {
      res.status(404);
    }
  },
};
</script>

<style lang="stylus">
.critical-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding 50px 30px

  &__title {
    font-size: 4em;
    line-height: 1;
    color var(--dark)
  }

  &__subtitle {
    font-size: 2.24em;
    line-height: 1;
    margin: 10px 0 20px;
    color var(--dark)
  }

  &__text {
    font-size: 1.125em;
    max-width: 450px;
    text-align: center;
    color var(--dark)
  }
}
</style>
