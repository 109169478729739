<template>
  <nav class="breadcrumbs">
    <div class="breadcrumbs__container">
      <div class="breadcrumbs__left">
        <ul class="breadcrumbs__links">
          <li>
            <router-link :to="{ name: 'home' }">Главная</router-link>
          </li>
          <li v-for="(link, i) in links" :key="i">
            <router-link :to="link.to">{{ link.title }}</router-link>
          </li>
        </ul>
        <h1 class="breadcrumbs__title">{{ title }}</h1>
      </div>
      <div class="breadcrumbs__right">
        <button type="button" class="breadcrumbs__button" @click="$router.go(-1)">
          <IconComponent category="default" name="arrow-left" />
          <span>Вернуться назад</span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "BreadcrumbsComponent",
  props: {
    title: {
      type: String,
    },
    links: {
      type: Array,
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.breadcrumbs {
  display flex
  align-items center
  width: 100%;
  justify-content center
  padding 21px 30px
  background var(--white)
  +below(1280px) {
    padding 15px 30px
  }

  &__container {
    display flex
    align-items center
    gap 30px
    justify-content space-between
    max-width var(--max-width)
    width 100%
  }

  &__left {
    display grid
    grid-gap 15px
    +below(1280px) {
      grid-gap 5px
    }
  }

  &__links {
    display flex
    align-items flex-start
    justify-content flex-start
    gap 41px

    li {
      display inline-flex
      position relative

      &:not(:last-child):after {
        content: "";
        height 1px
        width 10px
        background var(--dark-light)
        absolute right -25px top bottom
        margin auto 0
      }

      a {
        font-weight: 500;
        font-size: 0.875em
        line-height: 22px;
        color: var(--dark-light);
        +below(1280px) {
          font-size: 0.75em;
          line-height: 14px;
        }
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 2.125em;
    line-height: 41px;
    color: var(--dark);
    margin 0
    +below(1280px) {
      font-size: 1.5em;
      line-height: 29px;
    }
  }

  &__right {
    display inline-flex
    align-items center
    justify-content flex-end
    flex-shrink 0
    +below(768px) {
      display none
    }
  }

  &__button {
    border-radius 10px
    border 1px solid var(--gray-dark)
    background none
    font-weight: 500;
    font-size: 0.875em
    line-height: 22px;
    color: var(--dark-light);
    gap 10px
    padding 0 30px
    height 46px
    display inline-flex
    align-items center

    &:active {
      transform scale(0.96)
    }

    .icon {
      width 26px

      svg path {
        fill var(--dark-light)
      }
    }
  }
}
</style>
